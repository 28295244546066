import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormCheckbox, FormTextArea, FormButton } from '../components/form-elements';
import { navigate } from "gatsby";
import CartSummary from "../components/cart-summary";
import { useCheckoutActionContext, useCheckoutContext } from "../components/checkout-context";
import CheckoutSteps from "../components/checkout-steps";

export default function CheckoutNotes() {
    const { upsertOrderNotes } = useCheckoutActionContext();
    const { orderNotes } = useCheckoutContext();

    const [note, setNote] = useState(() => orderNotes.note ? orderNotes.note : "");
    const [isSurprise, setIsSurprise] = useState(() => orderNotes.isSurprise ? orderNotes.isSurprise : "");

    const form = {
        note, setNote,
        isSurprise, setIsSurprise,
    }

    const formSubmit = (e) => {
        e.preventDefault();
        upsertOrderNotes({ note, isSurprise })
        navigate('/checkout-review/');
    };

    return (
        <>
            <Seo title="Checkout Notes" />
            <BrandHeading title="checkout" />
            <section>
                <CartSummary />                
                <CheckoutSteps step="notes" />
                <Form form={form} onSubmit={formSubmit} />
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {
    return (
        <div className="grid grid-cols-1 gap-3 max-w-2xl mx-auto">
            <div>
                <h1 className="font-ovo text-base tracking-wider text-gray-800 mb-3">ORDER NOTES</h1>
                <p className="font-gotu text-xs tracking-wider leading-5 md:leading-[1.45rem] text-pas-gray">Please tell us if you have any dietary requirements, specific delivery instructions or other special requests.</p>
            </div>
            <FormTextArea label={""} value={form.note} onChange={form.setNote} />
            <div>
                <p className="font-ovo uppercase text-sm tracking-wider text-gray-800 mt-4 mb-3">Is this order a surprise? Tick if applicable.</p>
                <p className="font-gotu text-xs tracking-wider leading-5 md:leading-[1.45rem] text-pas-gray">Make sure your details are correct should we need to contact you.</p>
            </div>
            <FormCheckbox 
                className={"font-gotu text-xs tracking-wider leading-5 md:leading-[1.45rem] text-pas-gray mb-3"}
                label={"This is for a surprise, please do not contact the recipient."} value={form.isSurprise} onChange={form.setIsSurprise} />
            <hr />
            <div className="max-w-xs w-full mx-auto my-8">
                <FormButton
                    className={"w-full mb-4"}
                    label={"REVIEW"}
                    onClick={e => { onSubmit(e) }} />
                <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-fulfillment/`)} isSecondary={true} />
                <FormButton className="w-full mb-4" label={"RETURN TO CART"} onClick={() => navigate(`/cart/`)} isSecondary={true} />
            </div>
        </div>
    )
}
